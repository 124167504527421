import React, { Component } from 'react'

export class DownloadBox extends Component {
  render() {
    return (
      <> 
      <div id="downloadoverlay">
      </div>
      <div id="downloadbox">
        <span className="downloadtext">
          SUNKLAND.XYZ is an experimental web app for being with a multichannel audio composition. This will involve downloading a 140MB web application if you continue, consisting of many audio tracks and some graphics. While it loads you will be able to read more about the work and explore the map.          
        </span>
        <span className="downloadtext">
          SUNKLAND.XYZ is best viewed on modern web browsers such as Firefox and Chrome in a maximised window.
        </span>
        <button id="aocbutton" onClick={this.props.downloadStart}> <span id="startbuttontext"> CONTINUE </ span></button>
      </div>
      </>
    )
  }
}

export default DownloadBox
