import React, { Component } from 'react';
import './App.css';
import DownloadBox from './Components/downloadBox';
import GameBox from './Components/gameBox';
import LoadingScreen from './Components/loadingScreen';
import AOC from './Components/aoc';
import createjs from 'preload-js';

import {preloadExports} from './Assets/preloadArrays.js';

//Progress Loading
var queue = new createjs.LoadQueue(false);

queue.loadManifest(preloadExports);

export class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      aoc: true,
      downloadBox: false,
      loadingScreen: false,
      gameBox: false,
      currentPerc: 0,
      isLoaded: false,
      mobile: window.matchMedia('all and (any-hover: none)').matches,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.enterGame = this.enterGame.bind(this);
  }
  render() {
    return (
      <div id="wrapper">
        {this.state.mobile &&
        <div id="mobile-wrapper">
          <span id="mobiletext">Unfortunately SUNKLAND.XYZ is currently not able to operate on mobile devices due to technical restrictions. Please try again on a desktop or laptop computer if you are able to.</span>
        </div>          
        }
        {(this.state.aoc && !this.state.mobile) &&
          <AOC aocContinue={this.aocContinue.bind(this)}/> }
        {this.state.downloadBox &&
          <DownloadBox downloadStart={this.downloadStart.bind(this)} /> }
        {this.state.loadingScreen &&
          <LoadingScreen loadPercent={this.state.currentPerc} isLoaded={this.state.isLoaded} enterGame={this.enterGame}/> }
        {this.state.gameBox &&
          <GameBox setLoadPercent={this.setLoadPercent.bind(this)} setIsLoaded={this.setIsLoaded.bind(this)} width={this.state.width} height={this.state.height} /> }
        
      </div>
    )
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  aocContinue(){
    this.setState({aoc: false, downloadBox: true, loadingScreen: true});
  }
  downloadStart(){
    this.setState({downloadBox: false, gameBox: true, loadingScreen: true});
  }
  enterGame(){
    this.setState({loadingScreen: false});
    document.title = "SUNKLAND.XYZ";
  }
  setLoadPercent(currentPerc){
    this.setState({currentPerc: currentPerc});
    document.title = currentPerc + "% SUNKLAND.XYZ";
  }
  setIsLoaded(isLoaded){
    this.setState({isLoaded: isLoaded});
  }

  getCSSRule(ruleName) {
    ruleName = ruleName.toLowerCase();
    var result = null;
    var find = Array.prototype.find;

    find.call(document.styleSheets, styleSheet => {
        result = find.call(styleSheet.cssRules, cssRule => {
            return cssRule instanceof CSSStyleRule 
                && cssRule.selectorText.toLowerCase() === ruleName;
        });
        return result != null;
    });
    return result;
  }
}

export default App
