import React, { Component } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export class LoadingScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadBox: true,
      gameBox: false,
      currentLoadingText: 0
    }
  }
  render() {
    return (
      <div id="loadingscreenbox">
        <div className="LoadingScreenBackGroundLayers" id="loadingscreenbglayer1">
          <div className="LoadingScreenBackGroundLayers" id="loadingscreenbglayer2">
            <div className="LoadingScreenBackGroundLayers" id="loadingscreenbglayer3">
              <div className="LoadingScreenBackGroundLayers" id="loadingscreenbglayer4">
                <div className="LoadingScreenBackGroundLayers" id="loadingscreenbglayer5">
        </div> </div> </div> </div> </div>
        <div className="LoadingBarWrapper" id="loadingbarleft"> <div className="LoadingBarOuter" style={{height: this.props.loadPercent + '%'}} /> <div className="LoadingText"></div> <div className="LoadingBarInner" /> </ div>
        <div className="LoadingBarWrapper" id="loadingbarright"> <div className="LoadingBarOuter" style={{height: this.props.loadPercent + '%'}} /> <div className="LoadingText"></div> <div className="LoadingBarInner" /> </ div>
        <div id="loadingscreentextarea">
          <span>
            {loadingScreenTextVar[this.state.currentLoadingText]}
            {this.props.isLoaded &&
            <>
            <br /> <br />
            When you are ready to visit SUNKLAND.XYZ please click enter below.
            </> }  
          </span>
          <div id="LoadingScreenButtons">         
            <button className="LoadingScreenNavButton" id="lefticon" onClick={this.leftPress.bind(this)} />
            {!this.props.isLoaded
              ?<button className="LoadingScreenStartButton"> ENTER </button>
              :<button className="LoadingScreenStartButtonLoaded" onKeyDown={() => this.onKeyPressed} onClick={this.props.enterGame}> ENTER </button> }  
            <button className="LoadingScreenNavButtonActive" id="righticon" onClick={this.rightPress.bind(this)} />  
                 
        </div>
        </div>
        <TransformWrapper id="loadingscreencontourarea" wheel={{step: 0.05}} >
          <TransformComponent wrapperClass="loadingscreencontourarea">
          <img id="mappic" src={require('../Assets/neonArtboard 1@4x.png').default} alt="test" />
          </TransformComponent>
        </TransformWrapper>
        <div  id="map-instructions">
          <span>Double Click or Scroll to Zoom - Click and Drag to Pan</span>
        </div>  
      </div>
    )
  }
  componentDidMount() {
  }
  componentWillUnmount() {
  }

  leftPress(){
    if(this.state.currentLoadingText > 0){
      this.setState(prevState => ({currentLoadingText: prevState.currentLoadingText-1}));
      if(this.state.currentLoadingText === 1){
        let leftArrow = document.getElementById("lefticon");
        leftArrow.classList.remove('LoadingScreenNavButtonActive');
        leftArrow.classList.add('LoadingScreenNavButton');
      }
      if(this.state.currentLoadingText === loadingScreenTextVar.length -1){
        let rightArrow = document.getElementById("righticon");
        rightArrow.classList.remove('LoadingScreenNavButton');
        rightArrow.classList.add('LoadingScreenNavButtonActive');
      }
    }
  }
  rightPress(){
    if(this.state.currentLoadingText < loadingScreenTextVar.length -1){
      this.setState(prevState => ({currentLoadingText: prevState.currentLoadingText+1}));
      if(this.state.currentLoadingText === 0){
        let leftArrow = document.getElementById("lefticon");
        leftArrow.classList.remove('LoadingScreenNavButton');
        leftArrow.classList.add('LoadingScreenNavButtonActive');
      }
      if(this.state.currentLoadingText === loadingScreenTextVar.length -2){
        let rightArrow = document.getElementById("righticon");
        rightArrow.classList.remove('LoadingScreenNavButtonActive');
        rightArrow.classList.add('LoadingScreenNavButton');
      }
    }
  }
}

export default LoadingScreen

const loadingScreenTextVar = [
  <>Welcome to SUNKLAND.XYZ
  <br /> <br />
  This work was originally composed for 24.2 channels by Amias Hanley and first exhibited at Liquid Architecture’s Unheard Relations, 
  as part of McClelland Sculpture Park and Gallery’s Site &#038; Sound: Sonic art as ecological practice 2021.
  <br /> <br />
  ​​Here, we —Hanley and Hase— have created an interactive web-based presentation of this audio work, which invites you to move through, 
  explore and respond to the composition of sounds, textures and spatial arrangements.
  </>,
  <>SUNKLAND.XYZ presents a speculative swampland wherein multiple species, forms and forces are transforming through a series of open-ended 
  vocal exchanges. This swampland is a sonic imagining that is informed by a pre-colonised Carrum Carrum Swamp, settler governance of 
  watercourses and the drainage of wetlands on Bunurong Country. 
  </>,
  <>The swampland stretches across five different layers, each containing an element of the overall system. These layers represent slices 
  of tangled time—each carrying both remnants of the past and feelings of futurity, which coalesce in a kind of nonlinear discontinuous present. 
  <br /> <br />
  Your experience is extended into this space and time via the listener (the transparent sphere). The listener may travel within each layer either 
  by clicking with the mouse cursor or using the arrow keys. To traverse between different layers you may use the mouse scroll, the PG UP/PG DN 
  keys or by clicking the layer you wish to visit on the map in the lower left corner.
  </>,
  <>Throughout the swampland, you will find the practices of queer kinship thriving through the sonic body, expressed here as a site of 
  becoming and ambiguity. Exploring the poetics of care and exchange through themes of hybridity and heritage, SUNKLAND.XYZ calls upon you 
  to listen and imagine the ways that kinship might be felt through embodying the sonic expressions of another species or form—asking, 
  how might entering into a different corporeality and multispecies sense logic affect your decision-making and change how you listen? 
  </>,
  <>Keep in mind that this swampland is storied and co-constitutional. It is home to many caretakers, who continually exchange their 
  skins and scales—a moment where sacrifice and responsibility are absorbed by kin. Herein, you will encounter several sonic events 
  in which two or more organisms or geophysical forces engage in a kind of morphological phonic transition. However, the vocal 
  transition is never “completed”, it is not unilateral or unidirectional. Through these vocal exchanges a kind of interspecies 
  hybridity emerges, unfolding different ways of knowing (perhaps beyond the sense perception of one species) and shifting 
  understandings of one’s relations and responsibilities. Each sonic event is an offering through which to contemplate how 
  relationships of kin are established through exchange and becoming, attunement and affect. 
  </>
]

/*<div id="loadingscreentextarea">
          <span>
            {loadingScreenTextVar[this.state.currentLoadingText]}
          </span>
        </div> 
         <div className="LoadingBarInner" />

          <div id="loadingscreencontourarea">
              
          </div>
          
          <button className="LoadingScreenNavButton" id="rightbutton" onClick={this.rightPress.bind(this)}> <div id="righticon"/> <div id="rightbuttonbg"/> </button>
        */