import React, { Component } from 'react'

export class AOC extends Component {
  render() {
    return (
      <div id="aocbox">
        <span className="AOCText">
        The audio recordings that feature in this work were made on the unceded wetlands of the Bunurong peoples of the Kulin Nations. 
        We recognise the significance of the Bunurong peoples' aquacultural, spiritual, social and hydrological interrelationships with 
        these wetlands, particularly the former Carrum Carrum Swamp, and the impacts that colonisation and the subsequent draining of 
        wetlands has had upon those connections, practices, knowledge systems and the well-being of Bunurong Country.
        </span>
        <span className="AOCText">
        The interactive web-based presentation of this work, along with further audio recordings were produced on Wurundjeri Country, 
        where we both live. We offer our respect to Wurundjeri Elders and their Ancestors, those past, present and emerging, and 
        extend that respect to First Nations peoples across the continent—we recognise that the lands upon which we have come together 
        to make this work, and upon which the material pathways that allow our digital connectivity lie, are stolen lands and that 
        sovereignty has never been ceded.  
        </span>
        <button id="aocbutton" onClick={this.props.aocContinue}> CONTINUE </button>
      </div>
    )
  }
}

export default AOC
