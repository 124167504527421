import React, { useState, useEffect } from 'react';
import Unity, { UnityContext } from "react-unity-webgl";

const unityContext = new UnityContext({
  loaderUrl: "unitybuild/Builds.loader.js",
  dataUrl: "unitybuild/Builds.data",
  frameworkUrl: "unitybuild/Builds.framework.js",
  codeUrl: "unitybuild/Builds.wasm",
});

function GameBox(props){
  const [progression, setProgression] = useState(0);

  useEffect(function () {
    unityContext.on("progress", function (progression) {
      setProgression(progression);
      props.setLoadPercent(Math.round(progression * 100));
    });
  }, []);
  useEffect(function () {
    unityContext.on("loaded", function () {
      props.setIsLoaded(true);
    });
  }, []);

    return (
      <div id="gamewrapper">
        <div id="gameaspectholder">
          <Unity unityContext={unityContext} matchWebGLToCanvasSize={true} className={"GameCanvas"} />
        </div>
      </div>
      
    );
}

export default GameBox
